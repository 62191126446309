import React from "react";

export const Pos1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M21.5204 1.73563C23.5471 0.0881236 26.4529 0.088123 28.4796 1.73563C29.9187 2.90543 31.7887 3.40653 33.6199 3.11268C36.1984 2.69893 38.7135 4.15133 39.6442 6.59133C40.3055 8.32498 41.675 9.69448 43.4087 10.3558C45.8487 11.2865 47.3011 13.8016 46.8873 16.3801C46.5935 18.2113 47.0946 20.0813 48.2644 21.5204C49.9119 23.5471 49.9119 26.4529 48.2644 28.4796C47.0946 29.9187 46.5935 31.7887 46.8873 33.6199C47.3011 36.1984 45.8487 38.7135 43.4087 39.6442C41.675 40.3055 40.3055 41.675 39.6442 43.4087C38.7135 45.8487 36.1984 47.3011 33.6199 46.8873C31.7887 46.5935 29.9187 47.0946 28.4796 48.2644C26.4529 49.9119 23.5471 49.9119 21.5204 48.2644C20.0813 47.0946 18.2113 46.5935 16.3801 46.8873C13.8016 47.3011 11.2865 45.8487 10.3558 43.4087C9.69448 41.675 8.32498 40.3055 6.59133 39.6442C4.15133 38.7135 2.69893 36.1984 3.11268 33.6199C3.40653 31.7887 2.90543 29.9187 1.73563 28.4796C0.0881231 26.4529 0.0881235 23.5471 1.73563 21.5204C2.90543 20.0813 3.40653 18.2113 3.11268 16.3801C2.69893 13.8016 4.15133 11.2865 6.59133 10.3558C8.32498 9.69448 9.69448 8.32498 10.3558 6.59133C11.2865 4.15133 13.8016 2.69893 16.3801 3.11268C18.2113 3.40653 20.0813 2.90543 21.5204 1.73563Z"
        fill="url(#paint0_linear_310_885)"
        stroke="url(#paint1_linear_310_885)"
      />
      <g filter="url(#filter0_d_310_885)">
        <path
          d="M23.52 33.28V20.536L22.152 22.216H18L23.208 16H26.688V33.28H23.52Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_310_885"
          x="12"
          y="11"
          width="20.688"
          height="29.28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_310_885"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_310_885"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_310_885"
          x1="25"
          y1="0"
          x2="25"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4DC88" />
          <stop offset="1" stop-color="#D39B2D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_310_885"
          x1="24.805"
          y1="50.0452"
          x2="24.805"
          y2="-2.29461e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4DC88" />
          <stop offset="1" stop-color="#D39B2D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Pos2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M21.5204 1.73563C23.5471 0.0881236 26.4529 0.088123 28.4796 1.73563C29.9187 2.90543 31.7887 3.40653 33.6199 3.11268C36.1984 2.69893 38.7135 4.15133 39.6442 6.59133C40.3055 8.32498 41.675 9.69448 43.4087 10.3558C45.8487 11.2865 47.3011 13.8016 46.8873 16.3801C46.5935 18.2113 47.0946 20.0813 48.2644 21.5204C49.9119 23.5471 49.9119 26.4529 48.2644 28.4796C47.0946 29.9187 46.5935 31.7887 46.8873 33.6199C47.3011 36.1984 45.8487 38.7135 43.4087 39.6442C41.675 40.3055 40.3055 41.675 39.6442 43.4087C38.7135 45.8487 36.1984 47.3011 33.6199 46.8873C31.7887 46.5935 29.9187 47.0946 28.4796 48.2644C26.4529 49.9119 23.5471 49.9119 21.5204 48.2644C20.0813 47.0946 18.2113 46.5935 16.3801 46.8873C13.8016 47.3011 11.2865 45.8487 10.3558 43.4087C9.69448 41.675 8.32498 40.3055 6.59133 39.6442C4.15133 38.7135 2.69893 36.1984 3.11268 33.6199C3.40653 31.7887 2.90543 29.9187 1.73563 28.4796C0.0881231 26.4529 0.0881235 23.5471 1.73563 21.5204C2.90543 20.0813 3.40653 18.2113 3.11268 16.3801C2.69893 13.8016 4.15133 11.2865 6.59133 10.3558C8.32498 9.69448 9.69448 8.32498 10.3558 6.59133C11.2865 4.15133 13.8016 2.69893 16.3801 3.11268C18.2113 3.40653 20.0813 2.90543 21.5204 1.73563Z"
        fill="url(#paint0_linear_310_901)"
        stroke="url(#paint1_linear_310_901)"
      />
      <path
        d="M21.5204 1.73563C23.5471 0.0881236 26.4529 0.088123 28.4796 1.73563C29.9187 2.90543 31.7887 3.40653 33.6199 3.11268C36.1984 2.69893 38.7135 4.15133 39.6442 6.59133C40.3055 8.32498 41.675 9.69448 43.4087 10.3558C45.8487 11.2865 47.3011 13.8016 46.8873 16.3801C46.5935 18.2113 47.0946 20.0813 48.2644 21.5204C49.9119 23.5471 49.9119 26.4529 48.2644 28.4796C47.0946 29.9187 46.5935 31.7887 46.8873 33.6199C47.3011 36.1984 45.8487 38.7135 43.4087 39.6442C41.675 40.3055 40.3055 41.675 39.6442 43.4087C38.7135 45.8487 36.1984 47.3011 33.6199 46.8873C31.7887 46.5935 29.9187 47.0946 28.4796 48.2644C26.4529 49.9119 23.5471 49.9119 21.5204 48.2644C20.0813 47.0946 18.2113 46.5935 16.3801 46.8873C13.8016 47.3011 11.2865 45.8487 10.3558 43.4087C9.69448 41.675 8.32498 40.3055 6.59133 39.6442C4.15133 38.7135 2.69893 36.1984 3.11268 33.6199C3.40653 31.7887 2.90543 29.9187 1.73563 28.4796C0.0881231 26.4529 0.0881235 23.5471 1.73563 21.5204C2.90543 20.0813 3.40653 18.2113 3.11268 16.3801C2.69893 13.8016 4.15133 11.2865 6.59133 10.3558C8.32498 9.69448 9.69448 8.32498 10.3558 6.59133C11.2865 4.15133 13.8016 2.69893 16.3801 3.11268C18.2113 3.40653 20.0813 2.90543 21.5204 1.73563Z"
        fill="url(#paint2_linear_310_901)"
        stroke="url(#paint3_linear_310_901)"
      />
      <g filter="url(#filter0_d_310_901)">
        <path
          d="M16 33.28V26.656C16 26.032 16.152 25.472 16.456 24.976C16.76 24.48 17.16 24.088 17.656 23.8C18.152 23.496 18.704 23.344 19.312 23.344H29.68C29.76 23.344 29.824 23.32 29.872 23.272C29.936 23.208 29.968 23.136 29.968 23.056V19.432C29.968 19.352 29.936 19.288 29.872 19.24C29.824 19.176 29.76 19.144 29.68 19.144H19.432C19.352 19.144 19.28 19.176 19.216 19.24C19.168 19.288 19.144 19.352 19.144 19.432V20.608H16V19.312C16 18.688 16.152 18.128 16.456 17.632C16.76 17.136 17.16 16.744 17.656 16.456C18.152 16.152 18.704 16 19.312 16H29.8C30.408 16 30.96 16.152 31.456 16.456C31.968 16.744 32.368 17.136 32.656 17.632C32.96 18.128 33.112 18.688 33.112 19.312V23.2C33.112 23.792 32.96 24.344 32.656 24.856C32.368 25.352 31.968 25.752 31.456 26.056C30.96 26.36 30.408 26.512 29.8 26.512H19.432C19.352 26.512 19.28 26.544 19.216 26.608C19.168 26.656 19.144 26.72 19.144 26.8V29.848C19.144 29.928 19.168 30 19.216 30.064C19.28 30.112 19.352 30.136 19.432 30.136H33.112V33.28H16Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_310_901"
          x="10"
          y="11"
          width="29.1118"
          height="29.28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_310_901"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_310_901"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_310_901"
          x1="25"
          y1="0"
          x2="25"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4DC88" />
          <stop offset="1" stop-color="#D39B2D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_310_901"
          x1="24.805"
          y1="50.0452"
          x2="24.805"
          y2="-2.29461e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4DC88" />
          <stop offset="1" stop-color="#D39B2D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_310_901"
          x1="25"
          y1="0"
          x2="25"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F2" />
          <stop offset="1" stop-color="#A0A09D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_310_901"
          x1="24.805"
          y1="50.0452"
          x2="24.805"
          y2="-2.25543e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F2" />
          <stop offset="1" stop-color="#A0A09D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Pos3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M21.5204 1.73563C23.5471 0.0881236 26.4529 0.088123 28.4796 1.73563C29.9187 2.90543 31.7887 3.40653 33.6199 3.11268C36.1984 2.69893 38.7135 4.15133 39.6442 6.59133C40.3055 8.32498 41.675 9.69448 43.4087 10.3558C45.8487 11.2865 47.3011 13.8016 46.8873 16.3801C46.5935 18.2113 47.0946 20.0813 48.2644 21.5204C49.9119 23.5471 49.9119 26.4529 48.2644 28.4796C47.0946 29.9187 46.5935 31.7887 46.8873 33.6199C47.3011 36.1984 45.8487 38.7135 43.4087 39.6442C41.675 40.3055 40.3055 41.675 39.6442 43.4087C38.7135 45.8487 36.1984 47.3011 33.6199 46.8873C31.7887 46.5935 29.9187 47.0946 28.4796 48.2644C26.4529 49.9119 23.5471 49.9119 21.5204 48.2644C20.0813 47.0946 18.2113 46.5935 16.3801 46.8873C13.8016 47.3011 11.2865 45.8487 10.3558 43.4087C9.69448 41.675 8.32498 40.3055 6.59133 39.6442C4.15133 38.7135 2.69893 36.1984 3.11268 33.6199C3.40653 31.7887 2.90543 29.9187 1.73563 28.4796C0.0881231 26.4529 0.0881235 23.5471 1.73563 21.5204C2.90543 20.0813 3.40653 18.2113 3.11268 16.3801C2.69893 13.8016 4.15133 11.2865 6.59133 10.3558C8.32498 9.69448 9.69448 8.32498 10.3558 6.59133C11.2865 4.15133 13.8016 2.69893 16.3801 3.11268C18.2113 3.40653 20.0813 2.90543 21.5204 1.73563Z"
        fill="url(#paint0_linear_310_916)"
        stroke="url(#paint1_linear_310_916)"
      />
      <g filter="url(#filter0_d_310_916)">
        <path
          d="M19.336 33.28C18.712 33.28 18.152 33.128 17.656 32.824C17.16 32.52 16.76 32.12 16.456 31.624C16.152 31.128 16 30.576 16 29.968V28.936H19.144V29.848C19.144 29.928 19.168 30 19.216 30.064C19.28 30.112 19.352 30.136 19.432 30.136H29.68C29.76 30.136 29.832 30.112 29.896 30.064C29.96 30 29.992 29.928 29.992 29.848V26.392C29.992 26.312 29.96 26.248 29.896 26.2C29.832 26.136 29.76 26.104 29.68 26.104H18.976V22.96H29.008C29.088 22.96 29.152 22.936 29.2 22.888C29.264 22.824 29.296 22.752 29.296 22.672V19.432C29.296 19.352 29.264 19.288 29.2 19.24C29.152 19.176 29.088 19.144 29.008 19.144H19.432C19.352 19.144 19.28 19.176 19.216 19.24C19.168 19.288 19.144 19.352 19.144 19.432V20.56H16V19.312C16 18.688 16.152 18.128 16.456 17.632C16.76 17.136 17.16 16.744 17.656 16.456C18.152 16.152 18.712 16 19.336 16H29.128C29.752 16 30.312 16.152 30.808 16.456C31.304 16.744 31.696 17.136 31.984 17.632C32.288 18.128 32.44 18.688 32.44 19.312V22.816C32.44 23.008 32.424 23.208 32.392 23.416C32.36 23.608 32.312 23.792 32.248 23.968C32.536 24.272 32.752 24.632 32.896 25.048C33.056 25.448 33.136 25.856 33.136 26.272V29.968C33.136 30.576 32.984 31.128 32.68 31.624C32.376 32.12 31.976 32.52 31.48 32.824C30.984 33.128 30.432 33.28 29.824 33.28H19.336Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_310_916"
          x="10"
          y="11"
          width="29.1362"
          height="29.28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_310_916"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_310_916"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_310_916"
          x1="25"
          y1="0"
          x2="25"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFB695" />
          <stop offset="1" stop-color="#A95335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_310_916"
          x1="24.805"
          y1="50.0452"
          x2="24.805"
          y2="-1.08794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFB695" />
          <stop offset="1" stop-color="#A95335" />
        </linearGradient>
      </defs>
    </svg>
  );
};
