import React, { useState } from "react";
import { MainNavbar } from "./Navbar";
import styled from "styled-components";
import Orb from "./Orb";
import Banner from "./Banner";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { auth } from "../firebase";
import { errorToast } from "../App";
import { Steps } from "./Steps";
import { Intro } from "./Intro";
import Footer from "./Footer";

export const SERVER_URL = "https://vrd-418199335550.herokuapp.com/v1";
// export const SERVER_URL = "http://localhost:8080/v1";

function getQueryParam(url, param) {
  // Create a URL object
  const urlObj = new URL(url);

  // Use URLSearchParams to parse the query parameters
  const queryParams = new URLSearchParams(urlObj.search);

  // Get the value of the "code" query parameter
  return queryParams.get(param);
}

const Landing = () => {
  const [user, setUser] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  auth.onAuthStateChanged((data, err) => {
    setUser(data);
  });

  // const updateUserData = async (referral_code) => {
  //   const res = await fetch(`${SERVER_URL}/user`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       address: address,
  //       twitter_username: auth.currentUser.displayName,
  //       uid: auth.currentUser.uid,
  //       referred_by: referral_code,
  //       photo_url: auth.currentUser.photoURL,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return await res.json();
  // };
  // const url = window.location.toString();
  // const code = getQueryParam(url, "code");
  // if (code && !referralCode) {
  //   setReferralCode(code);
  // }

  // if (isConnected && user && address) {
  //   const url = window.location.toString();
  //   const code = getQueryParam(url, "code");
  //   if (code && !referralCode) {
  //     setReferralCode(code);
  //   }
  //   updateUserData(code)
  //     .then((res) => {
  //       if (res.success) {
  //         window.location.href = "/dashboard";
  //       } else {
  //         errorToast(res.error);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }
  return (
    <Container>
      <MainNavbar />
      <Banner referralCode={referralCode} />
      <Steps />
      <Intro />
      <Footer />
    </Container>
  );
};

export default Landing;

const Container = styled.div`
  background-image: url("/landing-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Cover ensures the background image covers the entire container */
`;
