import React, { useEffect, useState } from "react";
import { Dashboard, Flex } from "./StakeApp";
import Sidebar from "./Sidebar";
import Navbar, { truncateMiddle } from "./Navbar";
import styled from "styled-components";
import { Pos1, Pos2, Pos3 } from "./Icons";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toastSuccess } from "../App";
import { SERVER_URL } from "./Landing";
import Footer from "./Footer";

const Leaderboard = () => {
  const [userDetails, setUserDetails] = useState(null);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [leaderboard, setLeaderboard] = useState([]);

  const getUserDetails = async () => {
    const res = await fetch(`${SERVER_URL}/user/points?address=${address}`);
    const data = await res.json();

    setUserDetails(data);
    // const res2 = await fetch(
    //   `${SERVER_URL}/user/referrals?referred_by=${data?.referral_code}`
    // );
    // const data2 = await res2.json();
    // console.log(data2);
    // setReferrals(data2.count);
  };
  const getLeaderboard = async () => {
    const res = await fetch(
      `${SERVER_URL}/user/leaderboard?pageNumber=1&perPage=10`
    );
    const data = await res.json();
    console.log("LEADERBOARD ->", data);
    setLeaderboard(data);
  };

  useEffect(() => {
    if (address) {
      getUserDetails();
    }
    getLeaderboard();
  }, [address, isConnected]);

  return (
    <Flex>
      <Sidebar />
      <Dashboard>
        <Navbar />
        <Profile>
          <h3>Points: {userDetails?.points || 0}</h3>
          {/* <h3>Referrals: {referrals}</h3> */}
          <h3>
            {/* Referral Link:{" "}
            {`https://staking.viridis.network/?code=${
              userDetails?.referral_code || ""
            }`}{" "} */}
            {/* <CopyToClipboard
              text={`https://staking.viridis.network/?code=${
                userDetails?.referral_code || ""
              }`}
              onCopy={() => {
                // setCopyReferralLink(true);
                toastSuccess("Referral Link Copied!");
              }}
            >
              <ContentCopyIcon
                style={{ cursor: "pointer", marginBottom: -5 }}
              />
            </CopyToClipboard> */}
          </h3>
        </Profile>
        <Top>
          {leaderboard &&
            leaderboard?.slice(0, 3).map((item, idx) => (
              <Leader
                position={idx + 1}
                // twitter_username={item.twitter_username}
                wallet={item.address}
                points={item.points}
              />
            ))}
        </Top>
        <br />
        <br />
        <br />
        <br />
        <Table leaderboard={leaderboard} />
        <Footer />
      </Dashboard>
    </Flex>
  );
};

const Profile = styled.div`
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(100px);
  width: fit-content;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 3vw;
  border-radius: 20px;
  @media (max-width: 768px) {
    width: 90vw;
    font-size: 12px;
  }
`;

const Leader = ({ position, twitter_username, points, wallet }) => {
  return (
    <LeaderContainer>
      {position === 1 ? <Pos1 /> : position === 2 ? <Pos2 /> : <Pos3 />}
      <h2>{twitter_username}</h2> <hr />
      <Details>
        <Time>
          <h3>Wallet</h3>
          <div>{truncateMiddle(wallet, 6, 4)}</div>
        </Time>
        <Points>
          <h3>Points</h3>
          <div>{points}</div>
        </Points>
      </Details>
    </LeaderContainer>
  );
};

const Table = ({ leaderboard }) => {
  const screenWidth = useScreenWidth();
  return (
    <TableContainer>
      <thead>
        <tr>
          <th>Rank</th>
          {/* <th>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M15.6831 0H18.7365L12.0656 7.62474L19.9136 18H13.7685L8.95572 11.7073L3.44867 18H0.393177L7.52843 9.84464L0 0H6.30075L10.6513 5.75161L15.6831 0ZM14.6113 16.1722H16.3034L5.38146 1.73182H3.5659L14.6113 16.1722Z"
                fill="white"
              />
            </svg>
          </th> */}
          {screenWidth > 600 ? <td>Wallet Address</td> : ""}
          <th>Points</th>
        </tr>
      </thead>
      <tbody>
        {leaderboard &&
          leaderboard?.map((item, idx) => (
            <tr key={item.uid}>
              <td>{idx + 1}.</td>
              {/* <td>{item.twitter_username}</td> */}
              {screenWidth > 600 ? (
                <td>{truncateMiddle(item.address, 6, 6)}</td>
              ) : (
                ""
              )}
              <td>{item.points}</td>
            </tr>
          ))}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  border-radius: 16px;
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(100px);
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;

  width: 90%;
  thead {
    color: #878787;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  tbody {
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  th,
  td {
    padding: 20px;
    text-align: left;
  }
  tr {
    border-bottom: 0.5px solid #acacac;
  }
`;

export default Leaderboard;

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 90vw;
  gap: 1vw;
  padding: 0px 2vw;
  margin-top: 6vh;
`;

const LeaderContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #fff;
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(100px);
  width: 28vw;
  min-width: fit-content;
  color: white;
  padding: 20px 30px;
  position: relative;
  @media (max-width: 768px) {
    margin: 20px;
  }
  h2 {
    text-align: center;
    width: 100%;
  }
  svg {
    position: absolute;
    top: -13%;
    right: 45%;
  }
  hr {
    background: #acacac;
    height: 0.5px;
    border: none;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    text-align: center;
    font-family: "Montserrat";
  }
`;

const Time = styled.div``;

const Points = styled.div``;
