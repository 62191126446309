// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAkTJA86_8hkzDo9hrzQmXT3M1zLhiS1Qg",
  authDomain: "viridis-staking.firebaseapp.com",
  projectId: "viridis-staking",
  storageBucket: "viridis-staking.appspot.com",
  messagingSenderId: "238132166265",
  appId: "1:238132166265:web:63a2242f49c8e80d459ea8",
  measurementId: "G-R320EHQ16S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const analytics = getAnalytics(app);
