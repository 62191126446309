import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import React, { useState } from "react";
import styled from "styled-components";
import { ethers } from "ethers";
import { auth } from "../firebase";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { Link } from "react-router-dom";

const projectId = "cdfe03e466c6b2163cec6708112f4273";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum Mainnet",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://eth-mainnet.nodereal.io/v1/336906838ccd4ada98bad06c85251dd6",
};

// 3. Create modal
const metadata = {
  name: "Viridis Network Staking",
  description: "Staking for VRD Tokens",
  url: "https://staking.viridis.com",
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
});

export function truncateMiddle(str, startLength, endLength, separator) {
  if (!str) return;
  if (startLength == null) {
    startLength = 6;
  }
  if (endLength == null) {
    endLength = 4;
  }
  if (separator == null) {
    separator = "...";
  }
  if (str.length > startLength + endLength) {
    return (
      str.substring(0, startLength) +
      separator +
      str.substring(str.length - endLength)
    );
  } else {
    return str;
  }
}

export const MainNavbar = () => {
  return (
    <MainNavContainer>
      <NavImg src="/vrd-logo.png" />
      <Link to="https://staking.viridis.network">Home</Link>
      <Link to="https://viridis.network">About</Link>
    </MainNavContainer>
  );
};

const NavImg = styled.img`
  width: 70px;
`;

const NavLink = styled.span``;

const MainNavContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 70px;
  flex-shrink: 0;
  color: white;
  a {
    text-decoration: none;
    color: white;
  }
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(34, 205, 163, 0.25);
`;

export const ConnectWalletButton = () => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  return (
    <Button onClick={open}>{isConnected ? "Wallet" : "Connect Wallet"}</Button>
  );
};

export const TwitterUser = () => {
  return (
    <TwButton onClick={() => {}}>
      {auth?.currentUser?.displayName}{" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M15.6831 0H18.7365L12.0656 7.62474L19.9136 18H13.7685L8.95572 11.7073L3.44867 18H0.393177L7.52843 9.84464L0 0H6.30075L10.6513 5.75161L15.6831 0ZM14.6113 16.1722H16.3034L5.38146 1.73182H3.5659L14.6113 16.1722Z"
          fill="white"
        />
      </svg>
    </TwButton>
  );
};

const TwButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: Orbitron;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 2px solid #8a33f2;
  padding: 7px;
  cursor: pointer;
`;

const Navbar = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [ethersProvider, setEthersProvider] = useState();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const screenWidth = useScreenWidth();

  return (
    <Container>
      <h1>
        Welcome <GradientText>{truncateMiddle(address, 5, 4)}</GradientText>
      </h1>
      <NavButtons>
        {screenWidth > 600 ? <TwitterUser /> : ""}
        <ConnectWalletButton />
      </NavButtons>
    </Container>
  );
};

export default Navbar;

const NavButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92vw;
  padding: 10px 3vw;

  h1 {
    color: white;
  }
`;

const Button = styled.button`
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #22cda3 -14.9%,
    #4a91c2 47.15%,
    #8a33f2 109.02%
  );

  /* style */
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.07);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-weight: 900;
  padding: 12px 50px;
`;

const GradientText = styled.span`
  .gradient-text {
    font-size: 40px;
    font-weight: bold;
    background-image: linear-gradient(
      to right,
      red,
      orange,
      yellow,
      green,
      blue,
      indigo,
      violet
    );
    -webkit-background-clip: text;
    color: transparent;
  }
`;
