import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function PieActiveArc({ staked, unstaked }) {
  const [data, setData] = React.useState([
    { id: 0, value: 250000000, label: "Staked VRD" },
    { id: 1, value: 750000000, label: "Unstaked VRD" },
  ]);
  React.useEffect(() => {
    setData([
      { id: 0, value: parseInt(staked), label: "Staked VRD" },
      { id: 1, value: unstaked, label: "Unstaked VRD" },
    ]);
  }, [staked, unstaked]);
  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          paddingAngle: 5,
          innerRadius: 60,
          outerRadius: 80,
        },
      ]}
      height={200}
      legend={{ hidden: true }}
    />
  );
}
