import React from "react";
import styled from "styled-components";

export const Steps = () => {
  return (
    <Container>
      <Step>Link Wallet</Step>
      <Step>
        Link{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="32"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M15.6831 0H18.7365L12.0656 7.62474L19.9136 18H13.7685L8.95572 11.7073L3.44867 18H0.393177L7.52843 9.84464L0 0H6.30075L10.6513 5.75161L15.6831 0ZM14.6113 16.1722H16.3034L5.38146 1.73182H3.5659L14.6113 16.1722Z"
            fill="white"
          />
        </svg>
      </Step>
      <Step>Stake</Step>
      <Step>Get Pts.</Step>
    </Container>
  );
};

const Container = styled.div`
  background: #292929;
  display: flex;
  justify-content: space-around;
  padding: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Step = styled.div`
  padding: 10px;
  background: var(
    --title-gradent,
    linear-gradient(90deg, #22cda3 20.29%, #4a91c2 57.91%, #8a33f2 95.42%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Orbitron;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
`;
