import React from "react";
import { ConnectWalletButton } from "./Navbar";
import styled from "styled-components";
import Orb from "./Orb";
import { getAuth, signInWithRedirect } from "firebase/auth";
import { TwitterAuthProvider } from "firebase/auth";
import { auth } from "../firebase";

const provider = new TwitterAuthProvider();

const Banner = ({ referralCode }) => {
  console.log("REFERRAL ->", referralCode);
  return (
    <Container>
      <Text>
        <h2>Seamlessly Stake, Earn and Share with</h2>
        <Highlight>Viridis Network</Highlight>
        {referralCode && (
          <p>
            <b>Referral Code:</b> {referralCode}
          </p>
        )}
        <br />
        <Buttons>
          {/* <ConnectWalletButton /> */}
          <TwitterButton />
        </Buttons>
      </Text>
      <Img src="/landing-cat.png" />
      <OrbContainer>
        <Orb />
      </OrbContainer>
    </Container>
  );
};

export const TwitterButton = () => {
  const handleTwitterLogin = async () => {
    try {
      window.location.href = "/leaderboard";
      // await signInWithRedirect(auth, provider);
    } catch (error) {
      // console.log(error);
    }
  };

  return <TwButton onClick={handleTwitterLogin}>Leaderboard</TwButton>;
};

export default Banner;

const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 95vh;
  justify-content: space-around;
  align-items: center;
  padding: 5vh 5vw;
`;

const OrbContainer = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 60vw;
`;

const TwButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: Orbitron;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 2px solid #8a33f2;
  padding: 7px;
  cursor: pointer;
`;

const Highlight = styled.h2`
  background: var(
    --title-gradent,
    linear-gradient(90deg, #22cda3 20.29%, #4a91c2 57.91%, #8a33f2 95.42%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Orbitron;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  margin-top: -6vh;
  @media (max-width: 768px) {
    margin-top: -1vh;
  }
`;
const Text = styled.div`
  h2 {
    font-family: Orbitron;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    margin
    color: white;
  }
  z-index: 2;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
max-width: 60vw;
@media(max-width: 768px){
  h2{
  font-size: 30px;
  }
}
`;

const Img = styled.img`
  z-index: 2;
  width: 30vw;
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;
