import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PieChart from "./PieChart";
import { ethers } from "ethers";
import { StakingABI, VRDABI } from "../abi";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { errorToast, toastSuccess } from "../App";
import { CircularProgress } from "@mui/material";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { Link } from "react-router-dom";

const STAKING_CA = "0x729c05f7618622d71cfd307f4b867d818e11b9c8";

const Staking = () => {
  const [amount, setAmount] = useState("");
  const screenWidth = useScreenWidth();

  const [activeTab, setActiveTab] = useState("unstake");
  const [stakedAmount, setStakedAmount] = useState(0);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [vrdBalance, setVrdBalance] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [walletAddress, setWalletAddress] = useState();
  const [stakedVRD, setStakedVRD] = useState(0);
  const [userStakedVrd, setUserStakedVrd] = useState(0);
  const [loading, setLoading] = useState(false);

  const { open } = useWeb3Modal();

  function getStakingContract() {
    if (!isConnected) throw Error("User disconnected");
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const stakingContract = new ethers.Contract(STAKING_CA, StakingABI, signer);
    return stakingContract;
  }
  const getVRDContract = () => {
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const diceContract = new ethers.Contract(
      "0xf25304e75026E6a35FEDcA3B0889aE5c4D3C55D8",
      VRDABI,
      signer
    );
    return diceContract;
  };

  const allowVRDSpending = async (amount) => {
    const diceContract = getVRDContract();
    const tx = await diceContract.approve(STAKING_CA, amount);
    return tx;
  };

  const getVRDBalance = async (address) => {
    if (!address) return;
    console.log("ADDY ->", address);
    const vrdContract = getVRDContract();
    const stakingContract = getStakingContract();
    const balance = await vrdContract.balanceOf(address);
    const totalVrdStaked = await vrdContract.balanceOf(STAKING_CA);
    const userStakedVrd = await stakingContract.stakes(address);
    setStakedVRD(ethers.utils.formatEther(totalVrdStaked));
    setUserStakedVrd(ethers.utils.formatEther(userStakedVrd.amountStaked));
    setVrdBalance(ethers.utils.formatEther(balance));
  };

  const handleVrdStake = async () => {
    setLoading(true);
    try {
      if (!stakedAmount || stakedAmount < 0) {
        errorToast("Please Enter a valid amount of VRD tokens");
      } else if (stakedAmount > vrdBalance) {
        errorToast("Input Amount exceeds wallet balance");
      } else {
        const stakingContract = getStakingContract();
        const amountInWei = ethers.utils.parseEther(stakedAmount.toString());
        toastSuccess("Please Give VRD Allowance in your wallet.");
        const approveTx = await allowVRDSpending(amountInWei.toString());
        await approveTx.wait();
        toastSuccess("VRD Spending Approved. Staking your VRD");

        const tx = await stakingContract.stake(amountInWei.toString());
        await tx.wait();
        toastSuccess(
          `Your VRD is now staked! Tx: https://etherscan.io/${tx.hash}`
        );
        setVrdBalance((prev) => prev - stakedAmount);
        setStakedAmount((prev) => prev + stakedAmount);
        setUserStakedVrd((prev) => prev + stakedAmount);
        console.log(tx);
      }
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };

  const handleVrdUnstake = async () => {
    setLoading(true);
    try {
      const stakingContract = getStakingContract();
      toastSuccess("UnStaking your VRD");

      const tx = await stakingContract.requestUnstake();
      await tx.wait();
      toastSuccess(
        `Your VRD is now in the unstake holdup. You can claim it after 14 days. Tx: https://etherscan.io/${tx.hash}`
      );
      // setStakedAmount((prev) => 0);
      // setUserStakedVrd((prev) => 0);
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };
  const handleVrdClaim = async () => {
    setLoading(true);
    try {
      const stakingContract = getStakingContract();
      toastSuccess("Unstaking your VRD");

      const tx = await stakingContract.unstake();
      await tx.wait();
      toastSuccess(
        `Your VRD is now in the unstaked and sent to your wallet! Tx: https://etherscan.io/${tx.hash}`
      );

      setStakedAmount((prev) => 0);
      setUserStakedVrd((prev) => 0);
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isConnected && address) {
      setWalletAddress(address);
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (walletAddress) {
      getVRDBalance(walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (chainId !== 1) {
      errorToast("Please Switch to eth mainnet");
    }
  }, [chainId]);

  return (
    <Container>
      {/* <Stats>
        <Stat>
          <ImgDiv>
            <Img src="/assets.png" />
          </ImgDiv>

          <div>
            <h3>VRD Staked</h3>
            <h2>{parseInt(stakedVRD).toLocaleString()} VRD</h2>
          </div>
        </Stat>
        <Stat>
          <ImgDiv>
            <Img src="/vrd-personal.png" />
          </ImgDiv>
          <div>
            <h3>By You</h3>
            <h2>{parseInt(userStakedVrd).toLocaleString()} VRD</h2>
          </div>
        </Stat>
        <Stat>
          <ImgDiv>
            <Img src="/stat.png" />
          </ImgDiv>
          <div>
            <h3>VRD Supply</h3>
            <h2>1,000,000,000 VRD</h2>
          </div>
        </Stat>
      </Stats> */}
      <br />
      <Menu>
        <Left>
          <Picker>
            {/* <A
              active={activeTab === "stake"}
              onClick={() => setActiveTab("stake")}
            >
              Stake
            </A>{" "} */}
            <A
              active={activeTab === "claim"}
              onClick={() => setActiveTab("claim")}
            >
              Unstake
            </A>
          </Picker>
          <Staker>
            {activeTab === "stake" && <h1>Amount To Stake</h1>}
            {activeTab === "unstake" && <h1>Unstake VRD</h1>}
            {activeTab === "claim" && <h1>Unstake VRD</h1>}
            <br />
            {activeTab === "stake" && (
              <Input>
                <InputDiv>
                  <input
                    value={stakedAmount}
                    onChange={(e) =>
                      setStakedAmount(parseFloat(e.target.value))
                    }
                    type="number"
                  />
                  <button
                    onClick={() =>
                      setStakedAmount(parseInt(vrdBalance)?.toFixed(0))
                    }
                  >
                    MAX
                  </button>
                </InputDiv>
                <Right>
                  <Token>
                    <img src="/vrd-logo.png" />
                    VRD
                  </Token>
                  <Balance>
                    {activeTab === "stake" ? (
                      <>Balance: {parseInt(vrdBalance)?.toFixed(0)}</>
                    ) : (
                      <>Staked: {userStakedVrd}</>
                    )}
                  </Balance>
                </Right>
              </Input>
            )}
            <br />
            <StakeButton
              onClick={
                activeTab === "stake"
                  ? handleVrdStake
                  : activeTab === "unstake"
                  ? handleVrdClaim
                  : handleVrdClaim
              }
            >
              {loading ? (
                <CircularProgress />
              ) : (
                `${activeTab === "stake" ? "Stake" : "Unstake"} VRD`
              )}
            </StakeButton>
            {screenWidth <= 600 && (
              <Link to="/leaderboard">
                <StakeButton onClick={() => {}}>
                  {loading ? <CircularProgress /> : "Leaderboard"}
                </StakeButton>
              </Link>
            )}
          </Staker>
        </Left>
        <Analytics>
          <h1>Analytics</h1>
          <PieChartContainer>
            <PieChart
              staked={parseInt(stakedVRD)}
              unstaked={1000000000 - parseInt(stakedVRD)}
            />
            <Legends>
              <ChartLegend>
                <Rect color="rgb(2, 178, 175)"></Rect>Staked VRD
              </ChartLegend>
              <br />
              <ChartLegend>
                <Rect color="rgb(46, 150, 255)"></Rect>Unstaked VRD
              </ChartLegend>
            </Legends>
          </PieChartContainer>
        </Analytics>
      </Menu>
    </Container>
  );
};

export default Staking;
const Container = styled.div`
  border-radius: 16px;
  /* background: #1c1c1c; */
  backdrop-filter: blur(10px);
  padding: 5vh 3vw;
`;

const Picker = styled.div`
  background: #2b2b2b;
  width: fit-content;
  display: flex;
  cursor: pointer;
  color: white;
`;

const A = styled.div`
  padding: 10px;
  &:hover {
    background: whitesmoke;
    color: black;
  }
  background: ${({ active }) => (active ? "white" : "transparent")};
  color: ${({ active }) => (active ? "black" : "white")};
`;

const Stats = styled.div`
  border-radius: 16px;
  /* border: 2px solid white; */
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-around;
  width: 80vw;
  @media (max-width: 768px) {
    width: 95vw;
    flex-direction: column;
    justify-content: flex-start;
  }
  flex-wrap: wrap;
`;

const Stat = styled.div`
  display: flex;
  width: fit-content;
  color: white;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const ImgDiv = styled.div`
  padding: 5px 10px;
  border-radius: 13.754px;
  background: rgba(176, 176, 176, 0.1);
  height: fit-content;
`;
const Img = styled.img`
  width: 40px;
  object-fit: contain;
`;

const Staker = styled.div`
  color: white;
  border-radius: 10px;
  /* border: 1px solid #ececec; */
  width: 55vw;
  @media (max-width: 768px) {
    width: 92vw;
  }
  padding: 20px 30px 40px 30px;
  border-radius: 20px;
  margin-top: 5vh;
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(10px);
`;

const Input = styled.div`
  border-radius: 10px;
  border: 1px solid #ececec;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  gap: 10px;
  width: 50vw;
  @media (max-width: 768px) {
    width: 80vw;
  }
  padding: 20px;
  margin-bottom: 20px;
`;

const InputDiv = styled.div`
  flex: 1;
  position: relative;
  button {
    border-radius: 10px;
    padding: 4px;
    background: #22cda3;
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  input {
    background: transparent;
    outline: none;
    border: none;
    color: white;
    border-bottom: 1px solid white;
    width: 100%;
    padding: 10px;
    font-size: 20px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Token = styled.div`
  /* border-radius: 25px; */
  background: #2a2a2a;
  display: flex;
  align-items: center;
  width: fit-content;
  img {
    width: 30px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Balance = styled.div`
  font-family: Montserrat;
`;

const StakeButton = styled.button`
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #22cda3 -14.9%,
    #4a91c2 47.15%,
    #8a33f2 109.02%
  );

  /* style */
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  outline: none;
  border: none;
  padding: 15px 0px;
  font-size: 20px;
  color: white;
  font-weight: 900;
  cursor: pointer;
  margin-top: 10px;
`;
const PieChartContainer = styled.div`
  width: 40vw;
  @media (max-width: 768px) {
    width: fit-content;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  display: flex;
  margin-top: 5vh;
  @media (max-width: 768px) {
    margin-top: 0.5vh;
  }
  width: 90vw;
  /* flex-wrap: wrap; */
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Left = styled.div``;
const Analytics = styled.div`
  color: white;
  /* background: #1c1c1c; */
  backdrop-filter: blur(10px);
  width: fit-content;
  h1 {
    text-align: center;

    margin-left: -15%;
    @media (max-width: 768px) {
      margin=left: 0;
    }
  }
`;

const ChartLegend = styled.div`
  border-radius: 12px;
  background: #2b2b2b;
  display: flex;
  width: 216px;
  height: 43px;
  padding: 10px 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-left: -15%;
`;

const Rect = styled.div`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 6px;
  background: ${({ color }) => color};
`;

const Legends = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// <A
//   active={activeTab === "unstake"}
//   onClick={() => setActiveTab("unstake")}
// >
//   UnStake
// </A>
