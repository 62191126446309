import React from "react";
import styled from "styled-components";

export const Intro = () => {
  return (
    <Container>
      <Img src="/baloon.png" />
      <div>
        <h1>The Campaign</h1>
        <p>
          The Viridis Airdrop campaign aims to spread the word about the power
          of Regenerative Finance (#ReFi) and give you the opportunity to
          interact with our chain and protocol in testnet! Get testnet $VRD from
          our faucet, play around on chain with test carbon offsets, and earn
          rewards.
        </p>
        <p>
          Crucially, this testnet is directly tied to staking VRD. The greater
          your $VRD stake, the greater rewards you can earn for participation!
        </p>
      </div>
    </Container>
  );
};

const Img = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const Container = styled.div`
  display: flex;
  color: white;
  align-items: center;
  padding: 10px 3vw;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    h1,
    p {
      text-align: center;
    }
    img {
      width: 50vw;
    }
  }
  h1 {
    background: var(
      --title-gradent,
      linear-gradient(90deg, #22cda3 20.29%, #4a91c2 57.91%, #8a33f2 95.42%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Orbitron;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
  }
`;
