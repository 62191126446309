import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5";
import "./App.css";
import { toast } from "react-toastify";
import StakingApp from "./components/StakeApp";
import Leaderboard from "./components/Leaderboard";

// 1. Get projectId
const projectId = "cdfe03e466c6b2163cec6708112f4273";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum Mainnet",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://eth-mainnet.nodereal.io/v1/336906838ccd4ada98bad06c85251dd6",
};

// 3. Create modal
const metadata = {
  name: "Viridis Network Staking",
  description: "Staking Dashaboard for Viridis Network",
  url: "https://staking.viridis.network",
  icons: ["https://staking.viridis.network"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
});

export const errorToast = (msg) =>
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const toastSuccess = (msg) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<StakingApp />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
      </Routes>
    </Router>
  );
}

export default App;

// import React, { useState } from "react";
// import "./App.css";
// import {
//   createWeb3Modal,
//   defaultConfig,
//   useWeb3Modal,
//   useWeb3ModalAccount,
//   useWeb3ModalProvider,
// } from "@web3modal/ethers5/react";
// import { ethers } from "ethers";
// import { toast } from "react-toastify";
// import { StakingABI, VRDABI } from "./abi";
// import Sidebar from "./components/Sidebar";
// import styled from "styled-components";
// import Navbar from "./components/Navbar";
// import Staking from "./components/Staking";

// // 1. Get projectId
// const projectId = "cdfe03e466c6b2163cec6708112f4273";

// // 2. Set chains
// const mainnet = {
//   chainId: 1,
//   name: "Ethereum Mainnet",
//   currency: "ETH",
//   explorerUrl: "https://etherscan.io/",
//   rpcUrl: "https://eth-mainnet.nodereal.io/v1/336906838ccd4ada98bad06c85251dd6",
// };

// // 3. Create modal
// const metadata = {
//   name: "Viridis Network Staking",
//   description: "Staking Dashaboard for Viridis Network",
//   url: "https://staking.avaxdice.com",
//   icons: ["https://staking.viridis.network"],
// };

// createWeb3Modal({
//   ethersConfig: defaultConfig({ metadata }),
//   chains: [mainnet],
//   projectId,
// });

// export const errorToast = (msg) =>
//   toast.error(msg, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "light",
//   });

// export const toastSuccess = (msg) =>
//   toast.success(msg, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "dark",
//   });

// function App() {
//   const [walletAddress, setWalletAddress] = useState("");
//   // const [amount, setAmount] = useState("");
//   // const [stakedAmount, setStakedAmount] = useState(0);
//   const [ethersProvider, setEthersProvider] = useState();
//   const { address, chainId, isConnected } = useWeb3ModalAccount();
//   // const [diceBalance, setDiceBalance] = useState(0);
//   const { walletProvider } = useWeb3ModalProvider();
//   // const [loading, setLoading] = useState(false);
//   // const [congratulate, setCongratulate] = useState(false);
//   // const [poolBalance, setPoolBalance] = useState("0.0");
//   function getStakingContract() {
//     if (!isConnected) throw Error("User disconnected");
//     const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
//     const signer = ethersProvider.getSigner();
//     const stakingContract = new ethers.Contract(
//       "0x4446fFCe240DA2E886D8C2ae7589399DEe172dD0",
//       StakingABI,
//       signer
//     );
//     return stakingContract;
//   }
//   const getVRDContract = () => {
//     const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
//     const signer = ethersProvider.getSigner();
//     const diceContract = new ethers.Contract(
//       "0xf25304e75026E6a35FEDcA3B0889aE5c4D3C55D8",
//       VRDABI,
//       signer
//     );
//     return diceContract;
//   };
//   const allowVRDSpending = async (amount) => {
//     const diceContract = getVRDContract();
//     const tx = await diceContract.approve(
//       "0x4446fFCe240DA2E886D8C2ae7589399DEe172dD0",
//       amount
//     );
//     return tx;
//   };

//   const handleDiceStake = async () => {
//     try {
//       const stakingContract = getStakingContract();
//       const amountInWei = ethers.utils.parseEther("10000");
//       console.log(amountInWei.toString(), stakingContract);
//       console.log("staking");
//       const approveTx = await allowVRDSpending(amountInWei.toString());
//       await approveTx.wait();

//       const tx = await stakingContract.stake(amountInWei.toString());
//       await tx.wait();
//       console.log(tx);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const { open } = useWeb3Modal();
//   return (
//     <Flex>
//       <Sidebar />
//       <Dashboard>
//         <Navbar />
//         <Staking />
//       </Dashboard>
//     </Flex>
//   );
// }

// export default App;

// const Flex = styled.div`
//   display: flex;
//   background-image: url("/bg-dashboard.png");
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover; /* Cover ensures the background image covers the entire container */

//   width: 100vw;
// `;

// const Dashboard = styled.div`
//   position: relative;
//   flex: 0.9;
// `;
