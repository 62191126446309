import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Sidebar = () => {
  return (
    <Container>
      <Img src="/vrd-logo.png" />
      <br />
      <br />
      <Link to="/dashboard">
        <Icon src="/home-icon.png" />
      </Link>
      <br />
      <br />
      <Link to="/leaderboard">
        <Icon src="/leaderboard.png" />
      </Link>
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  background: #292929;
  box-shadow: -1.5px 0px 0px 0px rgba(255, 255, 255, 0.15) inset;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.1;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 100px;
`;
const Icon = styled.img`
  width: 50px;
  border-radius: 13px;
  background: #353535;
  padding: 17px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #8a33f2;
    background: #353535;
  }
`;
