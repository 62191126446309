import React from "react";
import styled from "styled-components";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";

const Footer = () => {
  return (
    <Container>
      <Img src="/vrd-logo.png" />
      <br />
      <Icons>
        <Link to="https://t.me/ViridisNetwork">
          <TelegramIcon sx={{ color: "white" }} />
        </Link>
        <Link to="https://viridis.network">
          <LanguageIcon sx={{ color: "white" }} />
        </Link>
        <Link
          style={{ textDecoration: "none", color: "white", marginTop: -5 }}
          to="https://vrdscan.io"
        >
          Go to testnet
        </Link>
        <Link to="https://medium.com/@viridisnetwork">
          <AppShortcutIcon sx={{ color: "white" }} />
        </Link>
      </Icons>
      <h4>
        Copyright © 2024 - All Rights Reserved By <Green>Viridis Network</Green>{" "}
      </h4>
    </Container>
  );
};

export default Footer;

const Img = styled.img`
  width: 50px;
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    color: white;
    text-align: center;
  }
  padding: 30px;
`;

const Green = styled.span`
  color: #22cda3;
  h4 {
    display: flex;
  }
`;
