import React, { useState } from "react";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { StakingABI, VRDABI } from "../abi";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import Navbar from "./Navbar";
import Staking from "./Staking";
import Footer from "./Footer";

function StakingApp() {
  const [walletAddress, setWalletAddress] = useState("");
  // const [amount, setAmount] = useState("");
  // const [stakedAmount, setStakedAmount] = useState(0);
  const [ethersProvider, setEthersProvider] = useState();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // const [diceBalance, setDiceBalance] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  // const [loading, setLoading] = useState(false);
  // const [congratulate, setCongratulate] = useState(false);
  // const [poolBalance, setPoolBalance] = useState("0.0");

  function getStakingContract() {
    if (!isConnected) throw Error("User disconnected");
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const stakingContract = new ethers.Contract(
      "0x4446fFCe240DA2E886D8C2ae7589399DEe172dD0",
      StakingABI,
      signer
    );
    return stakingContract;
  }
  const getVRDContract = () => {
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const diceContract = new ethers.Contract(
      "0xf25304e75026E6a35FEDcA3B0889aE5c4D3C55D8",
      VRDABI,
      signer
    );
    return diceContract;
  };
  const allowVRDSpending = async (amount) => {
    const diceContract = getVRDContract();
    const tx = await diceContract.approve(
      "0x4446fFCe240DA2E886D8C2ae7589399DEe172dD0",
      amount
    );
    return tx;
  };

  const handleDiceStake = async () => {
    try {
      const stakingContract = getStakingContract();
      const amountInWei = ethers.utils.parseEther("10000");
      console.log(amountInWei.toString(), stakingContract);
      console.log("staking");
      const approveTx = await allowVRDSpending(amountInWei.toString());
      await approveTx.wait();

      const tx = await stakingContract.stake(amountInWei.toString());
      await tx.wait();
      console.log(tx);
    } catch (error) {
      console.log(error);
    }
  };

  const { open } = useWeb3Modal();

  return (
    <Flex>
      <Sidebar />
      <Dashboard>
        <Navbar />
        <Staking />
        <Footer />
      </Dashboard>
    </Flex>
  );
}

export default StakingApp;

export const Flex = styled.div`
  display: flex;
  background-image: url("/bg-dashboard.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Cover ensures the background image covers the entire container */
  width: 100vw;
`;

export const Dashboard = styled.div`
  position: relative;
  flex: 0.9;
  @media (max-width: 600px) {
    flex: 1;
  }
`;
